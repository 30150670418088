<template>
  <div class="yishu-page">
    <div class="right"></div>
    <div class="left">
      <el-form ref="form" class="search-form" :model="searchFrom">
        <el-form-item>
          <el-date-picker
            v-model="searchFrom.dateTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            class="data-picker"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="info" class="search-btn" plain @click="onSubmit" :disabled="isDisabled">易数搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs"
export default {
  name:"search-page",
  data() {
    return {
      isDisabled:false,
      dataTime:"",
      searchFrom:{
        dateTime:""
      }
    }
  },
  methods:{
    onSubmit(){
      this.$router.push({
        name:"yishuContent",
        query:{
          time:dayjs(this.searchFrom.dateTime).unix() 
        }
      })
    }
  }
}
</script>
<style scoped>
.yishu-page {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  
}
.right {
  width: 75%;
  height: 100%;
  background-image: url("../../assets/youguan/banner1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.left {
  width: 25%;
  padding: 0 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #040114;
}
.search-form {
  width: 100%;
  text-align: left;
}
.search-form .data-picker {
  width: 322px;
}
.el-picker-panel {
  background-color: #040114;
}
.search-form .data-picker /deep/.el-input__inner {
  background-color: #040114;
}
.search-form .search-btn {
  color: #040114;
  background-color: #c0b4d8;
  width: 322px;
}
</style>